<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div v-if="campaigns.length > 0">
                <div class="mt-5 grid grid-cols-4 gap-4" v-for="campaign in campaigns" :key="campaign.id">
                    <div class="col-span-4">
                        <CampaignLargeItem 
                            :image="campaign.image" 
                            :title="campaign.title" 
                            :slug="campaign.slug" 
                            :sumDonation="campaign.sum_donation"
                            :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" /> 
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="mb-3 bg-red-500 text-white p-4 rounded-md">
                    Data Campaign Tidak Ditemukan!
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    //hook vue
    import { computed } from 'vue'

    //hook vuex
    import { useStore } from 'vuex'

    import CampaignLargeItem from '@/components/CampaignLargeItem.vue'

    export default {

        components: {
            CampaignLargeItem  // <-- register component FacebooLoader dari Vue Content Loader
        },

        setup() {

            //vuex
            const store = useStore()

            //digunakan untuk get data  state "campaigns" di module "campaign" 
            const campaigns = computed(() => {
                return store.state.campaign.campaigns
            })

            return {
                campaigns,      // <-- state campaigns
            }

        }

    }
</script>

<style>

</style>